'use strict';

// core version + modules:
import Swiper, { A11y } from 'swiper';
import Cursor from '../components/Cursor'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/a11y';

const Slider = {
    els: null,
    init: function () {
        Slider.els = document.querySelectorAll('.f-slider');
        if (Slider.els && Slider.els.length > 0) {
            Slider.els.forEach(el => {
                Slider.create(el);
            });
        }
    },
    create: function (el) {
        const slider = el.querySelector('.swiper');

        let swiper = null;

        swiper = new Swiper(slider, {
            modules: [A11y],
            a11y: true,
            spaceBetween: 30,
            slidesPerView: "auto",
            loop: false,
            speed: 600,
            autoHeight: false, 
            breakpoints: {
                840: {
                    spaceBetween: 70
                },
            }
        });
    },
};

export default Slider;