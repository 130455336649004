'use strict';

const Accordion = {
    init: function () {
        const buttons = document.querySelectorAll('.toggle-button');
        if (buttons && buttons.length > 0) {
            buttons.forEach(button => {
                Accordion.initOneElement(button)
            });
        }
    },
    initOneElement: function (button){
        button.addEventListener('click', () => {
            const toggleContent = button.parentElement.querySelector('.toggle-content') || button.parentElement.querySelector('.toggle-content-mobile');
            
            if (toggleContent) {

                button.classList.toggle('active');
                button.ariaExpanded = button.ariaExpanded === "true" ? false : true
                toggleContent.classList.toggle('active');

                // Autoplay loop videos inside accordions
                let childVideos = toggleContent.querySelectorAll('.co2-video')
                if ((childVideos.length && !window.greenmode) ||
                    (childVideos.length && window.greenmode === 'undefined')) {
                        
                    childVideos.forEach(video => {
                        if (toggleContent.classList.contains('active')) {
                            video.play()
                        }
                        else {
                            video.pause()
                        }
                    })
                }
            }
        });
    }
};

export default Accordion;