'use strict'

const Filters = {
    el: null,
    init: function () {
        Filters.el = document.querySelector('.c-filters');

        if (Filters.el) {
            const items = Filters.el.querySelectorAll('.c-filters__item');

            items.length && items.forEach(item => {
                item.addEventListener('click', () => {
                    if (item.classList.contains('active')) {
                        item.classList.remove('active')
                    }
                    else {
                        items.forEach(item => item.classList.remove('active'))
                        item.classList.add('active')
                    }
                })
            })
        }
    },
}


export default Filters;
