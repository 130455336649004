'use strict';

// core version + modules:
import Swiper, { Thumbs, EffectCreative } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/thumbs';
import 'swiper/css/effect-coverflow'
import 'swiper/css/effect-creative';

const Slider = {
    elMain: null,
    elSecond: null,
    init: function () {
        Slider.elMain = document.querySelector('.f-sliderAgency__main');
        Slider.elSecond = document.querySelector('.f-sliderAgency__second');
        if (Slider.elMain && Slider.elSecond) {
            Slider.create(Slider.elMain, Slider.elSecond);
        }
    },
    create: function (el, elS) {
        let swiperText = null;
        let swiperVideo = null;
        swiperVideo = new Swiper(".f-sliderAgency__second .swiper", {
            modules: [EffectCreative],
            allowTouchMove: false,
            loop: true,
            slidesPerView: 1,
            speed: 300,
            autoHeight: false,
            effect: "creative",
            creativeEffect: {
                prev: {
                    shadow: true,
                    translate: ["-20%", 0, -1],
                },
                next: {
                    translate: ["100%", 0, 0],
                },
            },
            on: {
                slideChange: (slider) => Slider.handleSlideChange(slider),
                init: () => Slider.handleInit()
            },
        });

        swiperText = new Swiper(".f-sliderAgency__main .swiper", {
            modules: [Thumbs],
            centeredSlides: false,
            spaceBetween: 0,
            slidesPerView: "auto",
            loop: true,
            speed: 100,
            autoHeight: false,
            thumbs: {
                swiper: swiperVideo,
            },
            breakpoints: {
                840: {
                    centeredSlides: true
                },
            }
        });
    },
    handleSlideChange: function (slider) {
        slider.slides.forEach((slide, index) => {
            const video = slide.querySelector('video')
            if (index === slider.activeIndex) {
                video.muted = true;
                video.loop = true;
                video.playsinline = true;
                video.play();
            }
            else {
                video.muted = true;
                video.pause();
            }
        })
    },
    /* Force muted (firefox bug) */
    handleInit: function () {
        Slider.elSecond.querySelectorAll('video').forEach(video => video.muted = true)
    }
};

export default Slider;