'use strict'

import axios from 'axios'
import Cursor from '../components/Cursor'
import Animations from '../components/Animations';
import Links from '../components/Links';

const ThinkingList = {
    el: null,
    filters: [],
    listElt: null,
    authorElt: null,
    loadMore: null,
    readMore:"",
    endpoint: `${process.env.MIX_PROXY}/wp-json/api/v1/posts`,
    // nbPerPage:2, //Géré par le back actuellement
    params: {
        "paged": 1,
        "filters[]": null,
        "from": null, 
        "lang": 'en'
    },
    init: function () {
        ThinkingList.el = document.querySelector('.f-thinkingList');
        if (ThinkingList.el) {
            ThinkingList.listElt = ThinkingList.el.querySelector('.f-thinkingList__list ul');
            ThinkingList.loadMore = ThinkingList.el.querySelector('#loadMore');
            ThinkingList.authorElt = ThinkingList.el.querySelector('.f-thinkingList__author');
            ThinkingList.readMore = ThinkingList.el.querySelector('.f-thinkingList__list').dataset.read;
            ThinkingList.params.lang = ThinkingList.el.querySelector('.f-thinkingList__list').dataset.lang;
            // Check if there's a filter parameter in the URL
            const urlParams = new URLSearchParams(window.location.search);
            const filterParam = urlParams.get('filter');
            const authorParam = urlParams.get('author');

            if (filterParam) {
                ThinkingList.params["filters[]"] = filterParam;
                ThinkingList.el.querySelector(`.c-filters__item[data-value="${filterParam}"]`).classList.add('active')
            }
            if (authorParam) {
                ThinkingList.params["from"] = authorParam;
            }
            
            if (ThinkingList.loadMore && ThinkingList.listElt) {
                ThinkingList.loadItems();
            }
            ThinkingList.filters = ThinkingList.el.querySelectorAll('.c-filters__item'); 
            ThinkingList.event();
        }
    },
    event:function () {
        ThinkingList.loadMore.addEventListener('click', () => {
            ThinkingList.params.paged += 1;
            ThinkingList.loadItems();
        });
        if (ThinkingList.filters.length) {
            ThinkingList.filters.forEach( filter => {
                if(filter.dataset.value){
                    filter.addEventListener('click', () => ThinkingList.filterItems(filter.dataset.value))
                }
            })
        }
        ThinkingList.authorElt.querySelector('.f-thinkingList__author-delete').addEventListener('click', () => {
            ThinkingList.authorElt.classList.remove('show--');
            ThinkingList.params["from"] = null;
            ThinkingList.authorElt.querySelector('.f-thinkingList__author-name').innerHTML="";
            const urlParams = new URLSearchParams(window.location.search);
            urlParams.delete('author');
            const newUrl = window.location.pathname + '?' + urlParams.toString();
            window.history.pushState({ path: newUrl }, '', newUrl);
            ThinkingList.loadItems();
        });
    },
    loadItems: async function () {
        ThinkingList.el.classList.add('loading--')
        const data = await axios.get(`${ThinkingList.endpoint}`, { params: ThinkingList.params })

        if (data.status === 200) {
            if(data.data && data.data.content && data.data.content.length > 0) {
                let items = data.data.content;
                items.forEach((item, key) => {
                    let li = document.createElement('li');
                    li.classList.add('f-thinkingPush__item')
                    li.innerHTML = `
                            <a href="${item.link}" title="${item.title}">
                                <div class="f-thinkingPush__item-img js-cursor-container bgdBlack--">
                                    <div class="c-cursor arrow--" aria-hidden="true">${ThinkingList.readMore}</div>
                                    <img src="${item.thumbnail.sizes.desktop}" alt="${item.thumbnail.alt}" title="${item.thumbnail.alt}" class="co2-image" />
                                </div>
                                <div class="f-thinkingPush__item-content">
                                    <p class="f-thinkingPush__item-category">${item.category}</p>
                                    <p class="f-thinkingPush__item-title c-link simple--">${item.title}</p>
                                    <p class="f-thinkingPush__item-description">${item.description}</p>
                                </div>
                            </a>
                        `;
                    ThinkingList.listElt.appendChild(li);
                    Cursor.initOneElement(li.querySelector('.js-cursor-container'));
                    Links.initOne(li.querySelector('.c-link'))
                    
                    if(!window.greenmode){
                        li.classList.add('js-slide-up');
                        Animations.animateSlideUp(li)
                    }
                });
                ThinkingList.loadMore.parentElement.classList.add('show--')
             
                if(ThinkingList.listElt.children.length >= data.data.total) {
                    ThinkingList.loadMore.parentElement.classList.remove('show--')
                }
            }
            else {
                console.log('aucun resultat');
                if(ThinkingList.params.paged=== 1) {
                    ThinkingList.listElt.innerHTML="";
                }
                
                ThinkingList.loadMore.parentElement.classList.remove('show--');
            }

            if(data.data && data.data.author) {
                ThinkingList.authorElt.querySelector('.f-thinkingList__author-name').innerHTML=`${data.data.author.display_name} - ${data.data.author.agency}`;
                ThinkingList.authorElt.classList.add('show--');
            }
            else {
                ThinkingList.authorElt.classList.remove('show--');
            }
        }
        else {
            ThinkingList.loadMore.parentElement.classList.remove('show--');
        }

        ThinkingList.el.classList.remove('loading--')
    }, 
    filterItems: function (filter) {
        // Reset page
        ThinkingList.params.paged = 1;
        const urlParams = new URLSearchParams(window.location.search);

        if(ThinkingList.params["filters[]"] === filter){
            ThinkingList.params["filters[]"] = null;
            urlParams.delete('filter');
        }
        else {
            ThinkingList.params["filters[]"] = filter;
            urlParams.set('filter', filter);
        }
        ThinkingList.listElt.innerHTML = ''
        ThinkingList.loadMore.parentElement.classList.add('show--')
        ThinkingList.loadItems();

        const newUrl = window.location.pathname + '?' + urlParams.toString();
        window.history.pushState({ path: newUrl }, '', newUrl);
    }
}


export default ThinkingList;