'use strict'

// A sample component

const Form = {
    el: null,
    groups: [],
    captcha: null,
    init: function () {
        Form.el = document.querySelector('.f-form');
        Form.groups = document.querySelectorAll('.f-form__group')
        Form.captcha = document.querySelector('.frc-captcha')

        if (Form.el && Form.groups.length) {
            Form.groups.forEach(group => {
                let input = group.querySelector('input') || group.querySelector('textarea');

                group.addEventListener('mouseenter', () => {
                    if(!input.disabled) group.classList.add('active')
                    })
                group.addEventListener('mouseleave', () => {
                    if (input.value === '' && document.activeElement !== input) {
                        group.classList.remove('active')
                    }
                })

                if (input) {
                    if(input.value !== "") group.classList.add('active')
                    
                    input.addEventListener('focus', () => {
                        if (!input.disabled) group.classList.add('active')
                    })
                    input.addEventListener('focusout', () => {
                        if (input.value === '' && document.activeElement !== input) {
                            group.classList.remove('active')
                        }
                    })
                    input.addEventListener('keydown', () => {
                        group.classList.add('active')
                    })
                }
            })
        }
    },

}


export default Form;
