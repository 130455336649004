import Header from "./components/Header";
import Slider from "./blocks/Slider";
import SliderAgency from "./blocks/SliderAgency";
import Video from "./components/Video";
import Accordion from "./blocks/Accordion";
import Select from "./components/Select";
import GreenMode from "./components/GreenMode";
import Cursor from "./components/Cursor";
import Form from "./blocks/Form";
import Agencies from "./blocks/Agencies";
import Filters from "./components/Filters";
import WorkList from "./blocks/WorkList";
import Modal from "./components/Modal";
import ThinkingList from "./blocks/ThinkingList";
import Wysiwyg from "./blocks/Wysiwyg";
import VideoLoop from "./components/VideoLoop";
import ReadMoreButton from "./components/ReadMoreButton"
import Animations from "./components/Animations";
import Links from "./components/Links";
import Logo from "./components/Logo";

const main = {
    init: function() {
        Header.init();
        Logo.init();
        Slider.init();
        SliderAgency.init();
        Video.init();
        Accordion.init();
        Select.init();
        GreenMode.init();
        Cursor.init();
        Form.init();
        Agencies.init();
        Filters.init();
        WorkList.init();
        Modal.init();
        ThinkingList.init();
        Wysiwyg.init();
        VideoLoop.init();
        ReadMoreButton.init();
        Animations.init();
        Links.init();
        document.getElementById('page').classList.add('loaded');
    }
};

export default main