'use strict'

import gsap from "gsap";

// A sample component

const ReadMoreButton =  {
    els: [], 
    init: function(){
        ReadMoreButton.els = document.querySelectorAll('.c-readMoreBtn'); 

        if(ReadMoreButton.els.length){
            ReadMoreButton.els.forEach( button => {
                const tl = ReadMoreButton.createTimeline(button)
                button.addEventListener('mouseenter', () =>  tl.play())
                button.addEventListener('mouseleave', () => tl.reverse())
            })
        }
    },
    createTimeline: function (button) {
        let tl = gsap.timeline({paused: true})
        tl.fromTo(button, 
            {    
                background: 'linear-gradient(180deg, #1C1C1C 0%, #111111 100%)',
                '--bgAfterColor1': '#3E3E3E',
                '--bgAfterColor2': '#0F0F0F',
            },
            {
                background: "linear-gradient(180deg, #00855B 0%, #0F0F0F 100%",
                '--bgAfterColor1': '#0F0F0F',
                '--bgAfterColor2': '#00855B',
                duration: 0.3,
                ease: 'power2.inOut'
            }
        )

        return tl
    }
}


export default ReadMoreButton;
