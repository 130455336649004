'use strict'

// A sample component

const VideoLoop =  {
    els: [],
    init: function(){
        VideoLoop.els = document.querySelectorAll('.c-video.loop--');
        if (VideoLoop.els && VideoLoop.els.length > 0) {
            VideoLoop.els.forEach(el => {
                VideoLoop.initOneElement(el)
            });
        }
    },
    initOneElement: function (el) {
        if (el.querySelector('.c-video__cta')) VideoLoop.ctaPlaying(el);
    },
    ctaPlaying: function (el) {
        const video = el.querySelector('video');
        const ctaButton = el.querySelector('.c-video__cta');

        if (video) {
            ctaButton.addEventListener('click', () => {
                if (video.paused) {
                    video.play();
                    video.classList.add('isPlaying--');
                } else {
                    video.pause();
                    video.classList.remove('isPlaying--');
                }
            });

            video.addEventListener('ended', () => {
                video.classList.remove('isPlaying--');
            });

            video.addEventListener('pause', () => {
                video.classList.remove('isPlaying--');
            });
        }
    }
}


export default VideoLoop;
