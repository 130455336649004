'use strict'

import gsap from "gsap";

const Cursor = {
    els: [],
    init: function () {
        Cursor.els = document.querySelectorAll('.js-cursor-container');

        if (Cursor.els.length) {

            Cursor.els.forEach(container => {
                Cursor.initOneElement(container);
            });
        }
    },
    initOneElement: function (container) {
        const cursor = container.querySelector('.c-cursor')
        container.addEventListener('pointermove', (e) => Cursor.handleMouseMove(e, cursor, container))
    },
    handleMouseMove: function (e, cursor, container) {
        if (!window.greenmode) {
            const sizes = Cursor.getSizes(container, cursor)
            let posX = ((e.offsetX - 0.5 * sizes.cursor.width) * 100) / sizes.container.width;
            let posY = ((e.offsetY - 0.5 * sizes.cursor.height) * 100) / sizes.container.height;

            gsap.to(cursor, {
                left: `${posX}%`,
                top: `${posY}%`,
                duration: 0,
                ease: 'linear'
            })

        }
    },
    getSizes: function (container, cursor) {
        return {
            cursor: {
                width: parseFloat(getComputedStyle(cursor).width),
                height: parseFloat(getComputedStyle(cursor).height),
            },
            container: {
                width: parseFloat(getComputedStyle(container).width),
                height: parseFloat(getComputedStyle(container).height),
            }
        }
    }
}


export default Cursor;
