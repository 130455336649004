'use strict'

import axios from 'axios'
import Accordion from './Accordion'
import GreenMode from '../components/GreenMode'
import { getImageSrc } from '../utils/utils'

const WorkList = {
    el: null,
    filters: [],
    list: [],
    loadMore: null,
    endpoint: `${process.env.MIX_PROXY}/wp-json/api/v1/more-work`,
    params: {
        "offset": 0,
        "filters[]": null, 
        "lang": 'en'
    },
    init: function () {
        WorkList.el = document.querySelector('.f-workList');

        if (WorkList.el) {
            WorkList.list = WorkList.el.querySelector('.f-workList__list');
            WorkList.params.lang = WorkList.list.dataset.lang
            WorkList.loadMore = WorkList.el.querySelector('#loadMore')
            
            if (WorkList.loadMore && WorkList.list) {
                WorkList.params["lang"] = WorkList.list.dataset.lang
                WorkList.accordionAriaLabel = WorkList.list.dataset.accordionAria
                WorkList.loadItems()

                WorkList.loadMore.addEventListener('click', () => {
                    WorkList.params.offset += 20;
                    WorkList.loadItems().then( () => {
                        // Accessibility : focus new elements
                        if(WorkList.params.offset < WorkList.list.querySelectorAll('.f-workList__item').length){
                            const firstNewItem = WorkList.list.querySelectorAll('.f-workList__item')[WorkList.params.offset]
                            let tabable = firstNewItem.querySelector('a') || firstNewItem.querySelector('button')
                            if(tabable) tabable.focus()                            
                        }
                    })
                    
                })
            }

            WorkList.filters = WorkList.el.querySelectorAll('.c-filters__item');

            if (WorkList.filters.length) {
                WorkList.filters.forEach(filter => {
                    if (filter.dataset.value) {
                        filter.addEventListener('click', () => WorkList.filterItems(filter.dataset.value))
                    }
                })
            }
        }
    },
    loadItems: async function () {
        WorkList.el.classList.add('loading--')

        const data = await axios.get(`${WorkList.endpoint}`, { params: WorkList.params })

        if (data.status === 200 && data.data.content && data.data.content.length > 0) {
            let items = data.data.content;

            items.forEach( (item)  => {
                const li = document.createElement('li');
                li.classList.add('f-workList__item')

                if (item.link) {
                    li.classList.add('link--');

                    li.innerHTML = `
                        <a href="${item.link ? item.link : '#'}" class="f-workList__item-title" title="${item.brand_name ? item.brand_name : ''}">${item.brand_name ? item.brand_name : ''}</a>
                        <span>${item.label ? item.label : ''}</span>
                    `
                }

                else if (item.content) {
                    li.classList.add('accordion--')

                    let toggleButton = document.createElement('button');
                    toggleButton.classList.add('f-workList__item-title', 'toggle-button')
                    toggleButton.textContent = item.title ? item.title : ''
                    toggleButton.ariaLabel = WorkList.accordionAriaLabel ? WorkList.accordionAriaLabel  : ''
                    toggleButton.ariaExpanded = false

                    let toggleContent = document.createElement('div')
                    toggleContent.classList.add('f-workList__item-content', 'toggle-content')


                    if (item.content.tags && item.content.tags.length > 0) {
                        let tagList = document.createElement('ul')
                        tagList.classList.add('f-workList__item-tags', 'c-tags')

                        item.content.tags.forEach(tag => {
                            tagList.innerHTML += `<li>${tag}</li>`
                        })

                        toggleContent.appendChild(tagList)
                    }

                    if (item.content.description) {
                        let desc = document.createElement('div')
                        desc.classList.add('c-wysiwyg')
                        desc.innerHTML = item.content.description
                        toggleContent.appendChild(desc)
                    }

                    if (item.content.medias && item.content.medias.length > 0) {

                        let mediaList = document.createElement('div')
                        mediaList.classList.add('f-workList__item-media', item.content.media_class)

                        item.content.medias.forEach((media, index) => {

                            if (media.type === 'image') {
                                let src = getImageSrc()

                                mediaList.innerHTML += `
                                    <div class="co2-image">
                                        <img src="${media.sizes[src]}" alt="${media.alt}"/>
                                    </div>
                                `
                            }
                            else if (media.type === 'video') {
                                mediaList.innerHTML += `
                                    <div>
                                        <video muted loop playsinline preload="metadata" class="co2-video">
                                            <source src="${media.mp4}" type="video/mp4"/>
                                        </video>
                                    </div>
                                `
                            }
                        })

                        toggleContent.appendChild(mediaList)
                    }

                    li.appendChild(toggleButton)
                    li.appendChild(toggleContent)
                }

                else if (item.title) {
                    li.innerHTML = `
                        <p class="f-workList__item-title">${item.title}</p>
                    `
                }

                WorkList.list.appendChild(li)
                if(li.querySelector('.toggle-button')){
                    Accordion.initOneElement(li.querySelector('.toggle-button'))
                }
                if(li.querySelector('.co2-video')){
                    GreenMode.refreshVideos()
                }
            })

            if (WorkList.params.offset + items.length >= data.data.total) {
                WorkList.loadMore.parentElement.classList.add('hide')
            }
        }
        else {
            WorkList.loadMore.parentElement.classList.add('hide')
        }

        WorkList.el.classList.remove('loading--')
    },
    filterItems: function (filter) {
        // Reset page
        WorkList.params.offset = 0;

        if (WorkList.params['filters[]'] === filter) {
            WorkList.params['filters[]'] = null
        }
        else {
            WorkList.params['filters[]'] = filter
        }

        WorkList.list.innerHTML = ''
        WorkList.loadMore.parentElement.classList.remove('hide')
        WorkList.loadItems()
    }
}


export default WorkList;
