'use strict'

// A sample component

const Links = {
    els: [],
    init: function () {
        Links.els = document.querySelectorAll('.c-link.simple--, .c-link.double--')

        if (Links.els.length) {
            Links.els.forEach(link => {
                Links.initOne(link)
            })
        }
    },
    initOne: function (link) {
        Links.checkHeight(link); 
        window.addEventListener('resize', () => Links.checkHeight(link))
    }, 
    checkHeight: function (link) {
        const lineHeight = parseFloat(getComputedStyle(link).lineHeight)
        const elHeight = link.getBoundingClientRect().height

        if(elHeight > lineHeight * 1.5) {
            if(link.classList.contains('simple--')){
                link.setAttribute('data-link-type', 'simple--')
                link.classList.remove('simple--')
            }
            else if(link.classList.contains('double--')){
                link.setAttribute('data-link-type', 'double--')
                link.classList.remove('double--')
            }
            link.classList.add('fade--')
        }
        else {
            if(link.getAttribute('data-link-type')){
                link.classList.remove('fade--'); 
                link.classList.add(link.getAttribute('data-link-type'))
            }
        }
    }
}


export default Links;
