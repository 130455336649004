'use strict'

import Header from "./Header"

// A sample component

const Logo =  {
    el: null,
    container: null,
    init: function(){
        Logo.el = document.querySelector('#logo')
        Logo.container = document.querySelector('#logo-container')

        if(Logo.el){
            Logo.updateLogo()
        }
    },
    updateLogo: function () {
        let logo;
        Logo.el.classList.remove('blend--')

        if (localStorage.getItem('green-mode') === 'on' 
            || window.greenmode
            || (window.innerWidth <= 840 && Header.el.classList.contains("open") && document.body.classList.contains('green'))
            || (window.innerWidth <= 840 && Header.el.classList.contains("open") && document.body.classList.contains('white'))
        ) {
            logo = document.createElement('img');
            logo.src = Logo.el.dataset.white;
            logo.alt = ""
            Logo.el.classList.add('blend--')
        }
        else if (Logo.el.dataset.video) {
            logo = document.createElement('video')
            logo.muted = true;
            logo.loop = true;
            logo.autoplay = true;
            logo.innerHTML = `<source src='${Logo.el.dataset.video}' type='video/mp4' />`
        }
        else if(Logo.el.dataset.animatedLogo){
            logo = document.createElement('img');
            logo.classList.add('animated--')
            logo.src = Logo.el.dataset.animatedLogo;
            logo.alt = ""
        }
        else if (document.body.classList.contains('green') || document.body.classList.contains('white')) {
            logo = document.createElement('img');
            logo.src = Logo.el.dataset.black;
            logo.alt = ""
        }
        else {
            logo = document.createElement('img');
            logo.src = Logo.el.dataset.white;
            logo.alt = ""
            Logo.el.classList.add('blend--')
        }

        let logoLink = Logo.el.querySelector('a');
        logoLink.innerHTML = '';
        logoLink.appendChild(logo)
    }
}


export default Logo;
