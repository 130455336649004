'use strict'

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// A sample component

const Animations = {
    slideUp: [],
    init: function () {
        gsap.registerPlugin(ScrollTrigger)

        Animations.slideUp = document.querySelectorAll('.js-slide-up');

    },
    animateSlideUp: function (el) {
        gsap.to(el, {
            top: 0,
            opacity: 1,
            scrollTrigger: {
                trigger: el,
                start: 'top 75%',
            },
            duration: 0.2,
            ease: 'linear'
        })
    },
    initAll: function () {
        if (Animations.slideUp.length) {
            Animations.slideUp.forEach(anim => {
                Animations.animateSlideUp(anim)
            })
        }
    },
    killAll: function () {
        if (Animations.slideUp.length) {
            Animations.slideUp.forEach(anim => {
                gsap.killTweensOf(anim);
                gsap.set(anim, { opacity: 1, top: 0 })
            });
        }
    }
}


export default Animations;
