'use strict'

const Agencies = {
    items: [],
    init: async function () {
        Agencies.items = document.querySelectorAll('.f-agencies__item')

        if (Agencies.items.length) {
            const userLocalTime = new Date()
            const utc = new Date(userLocalTime.getUTCFullYear(), userLocalTime.getUTCMonth(), userLocalTime.getUTCDate(), userLocalTime.getUTCHours(), userLocalTime.getUTCMinutes(), userLocalTime.getUTCSeconds())

            Agencies.items.forEach( agency => {

                /* Handle Time Display */
                let startHour = agency.dataset.starthour; 
                let endHour = agency.dataset.endhour; 
                let timezone = agency.dataset.timezone;

                if(timezone && startHour && endHour){
                    let localTime = Agencies.getLocalTime(timezone, utc);
                    let timeDiv = agency.querySelector('.f-agencies__item-time'); 
                    timeDiv.textContent = localTime;
    
                    if(Agencies.isWithinOpeningHours(localTime, startHour, endHour)){
                        agency.classList.add('opened--')
                    }
                }
            })
        }
    },
    getLocalTime: function (timezone) {
        /* Timezone format : GMT +02:00 */
        let isPositive = timezone.indexOf('+') !== -1
        let offsetHours = parseInt(timezone.slice(5, 7));
        let offsetMinutes = parseInt(timezone.slice(8));

        const userLocalTime = new Date()
        const utc = new Date(userLocalTime.getUTCFullYear(), userLocalTime.getUTCMonth(), userLocalTime.getUTCDate(), userLocalTime.getUTCHours(), userLocalTime.getUTCMinutes(), userLocalTime.getUTCSeconds())

        let localTime;

        if (isPositive) {
            localTime = new Date(utc.getTime() + (offsetHours * 60 * 60 * 1000) + (offsetMinutes * 60 * 1000))
        }
        else {
            localTime = new Date(utc.getTime() - (offsetHours * 60 * 60 * 1000) - (offsetMinutes * 60 * 1000))
        }

        let formatedTime = `${localTime.getHours() < 10 ? '0' : ''}${localTime.getHours()}:${localTime.getMinutes() < 10 ? '0' : ''}${localTime.getMinutes()}`


        return formatedTime
    },

    isWithinOpeningHours: function (currentTime, openTime, closeTime) {
        let agencyIsOpened = false;

        if (
            parseFloat(currentTime.replace(':', '')) >= parseFloat(openTime.replace(':', '')) &&
            parseFloat(currentTime.replace(':', '')) <= parseFloat(closeTime.replace(':', ''))
        ) {
            agencyIsOpened = true
        }

        return agencyIsOpened
    }
}


export default Agencies;
