'use strict'

import Animations from "./Animations"
import Logo from "./Logo"
import gsap from "gsap"

const GreenMode = {
    toggle: null,
    isActive: false,
    videos: [],
    animationIsPlaying: false,
    init: function () {
        GreenMode.videos = document.querySelectorAll('.co2-video')
        GreenMode.switch = document.querySelector('.c-co2')
        GreenMode.toggle = document.querySelector('.c-co2__toggle')

        if (GreenMode.switch) {
            window.addEventListener('load', () => {
                if (localStorage.getItem('green-mode') && localStorage.getItem('green-mode') === 'on') {
                    GreenMode.isActive = true;
                    GreenMode.enableGreenMode();
                    GreenMode.setToggleOn();
                    Animations.killAll();
                }
                else {
                    GreenMode.isActive = false;
                    GreenMode.setToggleOff();
                    Animations.initAll();
                    if (GreenMode.videos.length) GreenMode.updateVideos(true)

                }
            })


            GreenMode.switch.addEventListener('click', () => {
                if (!GreenMode.animationIsPlaying) {
                    if (GreenMode.isActive) {
                        GreenMode.disableGreenMode()
                        GreenMode.animateToggleOff()
                        Logo.updateLogo()
                        Animations.initAll()
                    }
                    else {
                        GreenMode.enableGreenMode()
                        GreenMode.animateToggleOn()
                        Logo.updateLogo()
                        Animations.killAll()
                    }
                    GreenMode.isActive = !GreenMode.isActive
                }
            })
        }

    },
    animateToggleOn: function () {
        GreenMode.animationIsPlaying = true
        gsap.to('.c-co2__toggle > *', {
            backgroundColor: "#00A76B"
        }),
            gsap.to('.c-co2__toggle-circle1', {
                left: 20
            })
        gsap.to('.c-co2__toggle-segment', {
            width: 18,
        })

        gsap.to('.c-co2__toggle-circle2', {
            left: 24,
            delay: 0.4
        })
        gsap.to('.c-co2__toggle-segment', {
            width: 0,
            left: 24,
            delay: 0.4,
            onComplete: () => GreenMode.animationIsPlaying = false
        }),
            gsap.to('.c-co2__toggle > *', {
                opacity: 1
            })
    },
    animateToggleOff: function () {
        GreenMode.animationIsPlaying = true
        gsap.to('.c-co2__toggle > *', {
            backgroundColor: "#FFF"
        })
        gsap.to('.c-co2__toggle-circle1', {
            left: 2
        })
        gsap.to('.c-co2__toggle-segment', {
            left: 11,
            width: 18
        })
        gsap.to('.c-co2__toggle-circle2', {
            left: 6,
            delay: 0.4
        })
        gsap.to('.c-co2__toggle-segment', {
            width: 0,
            delay: 0.4,
            onComplete: () => GreenMode.animationIsPlaying = false
        })
        gsap.to('.c-co2__toggle > *', {
            opacity: 1,
        })
    },
    enableGreenMode: function () {
        if (GreenMode.videos.length) GreenMode.updateVideos(false)
        document.body.classList.add('green-mode')
        localStorage.setItem('green-mode', 'on')
        window.greenmode = true
    },
    disableGreenMode: function () {
        if (GreenMode.videos.length) GreenMode.updateVideos(true)
        document.body.classList.remove('green-mode')
        localStorage.setItem('green-mode', 'off')
        window.greenmode = false
    },
    updateVideos: function (autoplay) {
        GreenMode.videos.forEach(video => {
            video.autoplay = autoplay
            if (autoplay){
                video.play()
                video.classList.add('isPlaying--')
            } 
            else {
                video.pause()
                video.classList.remove('isPlaying')
            }
        })
    },
    refreshVideos: function () {
        GreenMode.videos = document.querySelectorAll('.co2-video')
    },
    setToggleOn: function () {
        gsap.set('.c-co2__toggle-circle1', { left: 20 })
        gsap.set('.c-co2__toggle-circle2', { left: 24 })
        gsap.set('.c-co2__toggle-segment', { left: 24 })
        gsap.set('.c-co2__toggle > *', { backgroundColor: "#00A76B", opacity: 1 })
    },
    setToggleOff: function () {
        gsap.set('.c-co2__toggle-circle1', { left: 2 })
        gsap.set('.c-co2__toggle-circle2', { left: 6 })
        gsap.set('.c-co2__toggle-segment', { left: 11 })
        gsap.set('.c-co2__toggle > *', { backgroundColor: "#FFF", opacity: 1 })
    }
}


export default GreenMode;
