'use strict'

import Cursor from './Cursor';
import Video from './Video';
import Wysiwyg from '../blocks/Wysiwyg';
import axios from 'axios'
import { getImageSrc } from '../utils/utils';
import gsap from 'gsap'

const Modal = {
    openBtns: [],
    closeBtn: null,
    content: null,
    bg: null,
    pannel: null,
    el: null,
    els: [],
    endpoint: `${process.env.MIX_PROXY}/wp-json/api/v1/modal`,
    init: function () {
        Modal.el = document.querySelector('.c-modal');
        Modal.openBtns = document.querySelectorAll('.open-modal')
        Modal.closeBtn = Modal.el.querySelector('.c-modal__close')
        Modal.bg = Modal.el.querySelector('.c-modal__bg')
        Modal.pannel = Modal.el.querySelector('.c-modal__pannel')
        Modal.content = Modal.el.querySelector('.c-modal__content')

        if (Modal.el && Modal.closeBtn && Modal.openBtns.length) {

            Modal.openBtns.forEach(btn => {
                btn.addEventListener('click', () => {
                    Modal.loadContent(btn)
                    Modal.animateSlideIn(Modal.bg, Modal.pannel, btn)
                    btn.ariaExpanded = true
                    window.addEventListener('keydown', Modal.trapFocus)
                })
            })

            Modal.closeBtn.addEventListener('click', () => {
                Modal.animateSlideOut(Modal.bg, Modal.pannel, Modal.content)
                window.removeEventListener('keydown', Modal.trapFocus)
            })
            Modal.bg.addEventListener('click', () => {
                Modal.animateSlideOut(Modal.bg, Modal.pannel, Modal.content)
                window.addEventListener('keydown', Modal.trapFocus)
            })
            window.addEventListener('resize', () => {
                Modal.animateSlideOut(Modal.bg, Modal.pannel, Modal.content)
                window.addEventListener('keydown', Modal.trapFocus)
            })
        }
    },
    animateSlideIn: function (background, pannel) {
        document.body.classList.add('no-scroll--')

        gsap.to(background, {
            left: 0,
            duration: 0
        })
        gsap.to(background, {
            backdropFilter: 'blur(20px)',
            duration: 0.2
        })
        gsap.to(pannel, {
            right: 0,
            autoAlpha: 1,
            delay: 0.2,
            duration: 0.4,
            onComplete: () => {
                if (Modal.closeBtn) {
                    Modal.closeBtn.focus()
                }
            }
        })

    },
    animateSlideOut: function (background, pannel, content = null) {
        const width = getComputedStyle(pannel).width;

        gsap.to(pannel, {
            right: `-${width}`,
            autoAlpha: 0,
            duration: 0.4,
            ease: 'power2.out'
        })
        gsap.to(background, {
            backdropFilter: 'blur(0px)',
            duration: 0.2,
            delay: 0.2,
        }, '< += 0.1')
        gsap.to(background, {
            left: '100vw',
            delay: 0.4,
            duration: 0,
            onComplete: () => {
                if (content) content.innerHTML = null
                if (pannel.dataset.id) {
                    const relatedBtn = document.querySelector(`button.open-modal[data-id= '${pannel.dataset.id}']`)
                    if (relatedBtn) {
                        relatedBtn.focus()
                        relatedBtn.ariaExpanded = false
                    }
                }
            }
        })

        document.body.classList.remove('no-scroll--')
    },
    loadContent: async function (button) {
        let id = button.dataset.id;
        let videoAriaLabel = button.dataset.videoAria
        if (id) {
            let data = await axios.get(`${Modal.endpoint}/${id}`)

            if (data.status === 200 && data.data?.content?.length) {
                let flexibles = data.data.content;

                flexibles.forEach(flexible => {

                    switch (flexible.acf_fc_layout) {
                        case 'image':
                            Modal.generateImageBloc(flexible)
                            break;

                        case 'citation':
                            Modal.generateQuoteBloc(flexible)
                            break;

                        case 'wysiwyg':
                            Modal.generateTextBloc(flexible)
                            break;

                        case 'video':
                            Modal.generateVideoBloc(flexible, videoAriaLabel)
                            break;

                        default:
                            null
                            break;
                    }
                })
            }
        }

        Modal.pannel.dataset.id = button.dataset.id
    },
    generateImageBloc: function (flexible) {
        let bloc = document.createElement('div')
        bloc.classList.add('f', 'f-fullMedia')
        bloc.innerHTML = `  
            <div class="container">      
                <div class="f-fullMedia__ctn">
                    <div class="c-img co2-image">
                        <figure role="figure">
                            <picture>
                                <img loading="lazy" src="${flexible.image.sizes[getImageSrc()]}" alt="${flexible.image.alt ? flexible.image.alt : ''}">
                            </picture>
                        </figure>
                    </div>
                </div>
            </div>
        `

        Modal.content.appendChild(bloc)
    },
    generateQuoteBloc: function (flexible) {
        let bloc = document.createElement('div');
        bloc.classList.add('f', 'f-quote');
        bloc.innerHTML = `
            <div class="container">
                <blockquote class="f-quote__ctn">
                    <p class="f-quote__ctn-quote">${flexible.citation}</p>
                    ${flexible.caption && `<span class="f-quote__ctn-caption">${flexible.caption}</span>`}
                    <span class="f-quote__ctn-caption"><?php echo( $args['caption'] ); ?></span>
                </blockquote>
            </div>
        `

        Modal.content.appendChild(bloc)
    },
    generateTextBloc: function (flexible) {
        let bloc = document.createElement('div')
        bloc.classList.add('f', 'f-wysiwyg')
        bloc.innerHTML = `
            <div class="container">
                <div class="f-wysiwyg__ctn" data-colcount="1">
                    <div class="f-wysiwyg__ctn-column c-wysiwyg" data-width="1">
                        ${flexible.wysiwyg}
                    </div>
                </div>
            </div>
        `

        Modal.content.appendChild(bloc)

        if (bloc.querySelector('.c-button.arrow--')) {
            Wysiwyg.initOne(bloc.querySelector('.c-button.arrow--'))
        }
    },
    generateVideoBloc: function (flexible, ariaLabel) {
        let bloc = document.createElement('div')
        bloc.classList.add('f', 'f-fullMedia')
        let src = getImageSrc()

        if (flexible.format_video === "interne") {
            bloc.innerHTML = `
                <div class="container">
                    <div class="f-fullMedia__ctn">
                        <div class="c-video">
                            <figure role="figure">
                                <div class="c-video__player">
                                    <video controls poster=${flexible.image_video && `${flexible.image_video.sizes[src]}    `}>
                                        <source src="${flexible.video_mp4.mp4}" type="video/mp4">
                                    </video>
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
            `
        }
        else if (flexible.plateforme && flexible.plateforme !== "") {
            let markupVideo;
            if (flexible.plateforme === "youtube") {
                if (tarteaucitron.state.youtube) {
                    markupVideo = `
                        <div class="youtube_player external_player" videoID="${flexible.id_video}" width="100%" height="100%" theme="dark" rel="0" controls="1" showinfo="0" autoplay="0" mute="0" srcdoc="srcdoc" loop="0" loading="1">
                            <iframe type="text/html" ' src="//www.youtube-nocookie.com/embed/${flexible.id_video}" height="100%" width="100%" frameborder="0" title="Youtube video" allowfullscreen></iframe>'
                        </div>`
                }
                else {
                    markupVideo = `
                        <div class="youtube_player external_player" videoID="${flexible.id_video}" width="100%" height="100%" theme="dark" rel="0" controls="1" showinfo="0" autoplay="0" mute="0" srcdoc="srcdoc" loop="0" loading="1">
                            ${tarteaucitron.engage('youtube')}
                        </div>
                    `
                }
            }
            else if (flexible.plateforme === "dailymotion") {
                if (tarteaucitron.state.dailymotion) {
                    markupVideo = `
                        <div class="dailymotion_player external_player" videoID="${flexible.id_video}" width="100%" height="100%" showinfo="0" autoplay="0" embedType="video">
                           <iframe src="//www.dailymotion.com/embed/video/${flexible.id_video}" height="100%" width="100%" frameborder="0" title="Dailymotion video" allowfullscreen></iframe>
                        </div>
                    `
                }
                else {
                    markupVideo = `
                        <div class="dailymotion_player external_player" videoID="${flexible.id_video}" width="100%" height="100%" showinfo="0" autoplay="0" embedType="video">
                            ${tarteaucitron.engage('dailymotion')}
                        </div>
                    `
                }
            }
            else if (flexible.plateforme === "vimeo") {
                if (tarteaucitron.state.vimeo) {
                    markupVideo = `
                        <div class="vimeo_player external_player" videoID="${flexible.id_video}" width="100%" height="100%">
                            <iframe src="//player.vimeo.com/video/${flexible.id_video}" width="100%" height="100%" frameborder="0" title="Vimeo video" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';   
                        </div>
                    `
                }
                else {
                    markupVideo = `
                        <div class="vimeo_player external_player" videoID="${flexible.id_video}" width="100%" height="100%">
                            ${tarteaucitron.engage('vimeo')}
                        </div>
                    `
                }
            }

            bloc.innerHTML = `
                <div class="container">
                    <div class="f-fullMedia__ctn">
                        <div class="c-video external--">
                            <figure role="figure">
                                <div class="c-video__player">
                                    ${flexible.image_video ?
                                    `
                                        <div class="c-video__player-poster js-cursor-container co2-image"
                                            style="background-image: url('${flexible.image_video.sizes[src]}');">
                                            <button type="button" class="c-video__player-poster-play" aria-label="${ariaLabel ? ariaLabel : ''}">
                                                <i class="icon-playfull" aria-hidden="true"></i>
                                            </button>
                                            <div class="c-cursor play--" aria-hidden="true">Play</div>
                                        </div>
                                    `
                                    : ''}
                                    ${markupVideo}
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
            `
        }

        Modal.content.appendChild(bloc)

        if (bloc.querySelector('.c-video')) {
            Video.initOneElement(bloc.querySelector('.c-video'))
        }

        if (bloc.querySelector('.js-cursor-container')) {
            Cursor.initOneElement(bloc.querySelector('.js-cursor-container'))
        }

    }, 
    trapFocus: function (e) {

        let isTabPressed = e.key === "Tab" || e.keyCode === 9;
        if (!isTabPressed) return;
            
        const focusableElts = Modal.pannel.querySelectorAll(
          'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
        );
        
        const firstFocusableElt = focusableElts[0];
        const lastFocusableElt = focusableElts[focusableElts.length - 1];
    
        // Back tab
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElt) {
            lastFocusableElt.focus();
            e.preventDefault();
          }
        }
        // Forward tab
        else {
          if (document.activeElement === lastFocusableElt) {
            firstFocusableElt.focus();
            e.preventDefault();
          }
        }
      },
}

export default Modal;
