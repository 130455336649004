'use strict';

const Wysiwyg = {
    els: null,
    init: function () {
        Wysiwyg.els = document.querySelectorAll('.c-wysiwyg .c-button.arrow--');
        Wysiwyg.els.forEach(el => {
            Wysiwyg.initOne(el);
        });
    },
    initOne: function(el) {
        if(!el.querySelector('span')) {
            const spanElement = document.createElement('span');
            el.appendChild(spanElement);
        }
    }
};

export default Wysiwyg;