'use strict';

const Video = {
    els: null,
    init: function () {
        Video.els = document.querySelectorAll('.c-video:not(.loop--)');

        if (Video.els && Video.els.length > 0) {
            Video.els.forEach(el => {
                Video.initOneElement(el)
            });
        }
    },
    initOneElement: function (el) {
        Video.managePoster(el);
    },
    managePoster: function (el) {
        const poster = el.querySelector('.c-video__player-poster');
        if (poster) {
            poster.addEventListener('click', () => {
                poster.classList.add('hidden');
                if (el.querySelector('.external_player') && el.querySelector('iframe')) {
                    let src = el.querySelector('iframe').getAttribute('src').replace('autoplay=0', '').replace('muted=0', '').replace('playsinline=0', '')
                    if (src.includes('?')) {
                        src += "&autoplay=1&playsinline=1"
                    }
                    else {
                        src += "?autoplay=1&playsinline=1"
                    }
                    el.querySelector('.external_player').innerHTML = `
                        <iframe  frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; playsinline; encrypted-media; gyroscope; picture-in-picture" title="Video player" width="100%" height="100%" src="${src}"></iframe>
                    `
                }
            });
        }
    }
};

export default Video;